.ementas_digitais {
  .FoodItemDetails {
    .foodImage {
      float: left;
      width: 570px;
      height: 330px;
      margin: 6px 0;

      img {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }

    .foodMain {
      width: 100%;
      float: left;
    }

    .foodInfo {
      width: 100%;
      float: left;
    }

    .foodName {
      width: 65%;
      float: left;
      font-size: 18px;
    }

    .foodPrice {
      width: 35%;
      float: left;
      text-align: right;
      font-size: 14px;
      color: #5a8fd2;

      span {
        width: 100%;
        float: left;
      }
    }

    .vatInfo {
      float: left;
      width: 100%;
      text-align: right;
      font-size: 10px;
      color: #5a8fd2;
    }

    .foodNutrition {
      background: #5a8fd2;
      width: 100%;
      box-sizing: border-box;
      color: #ffffff;
      padding: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      img {
        margin-left: 6px;
        width: 16px;
        height: 16px;
      }
    }

    .foodDescription {
      width: 100%;
      box-sizing: border-box;
      padding: 16px;
      font-size: 18px;
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
      position: relative;
      max-height: 150px;
      overflow: scroll;
    }

    button {
      padding: 6px 12px;
      border-radius: 4px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      color: #333333;
      background-color: #ffffff;
      border: #cccccc solid 1px;
      outline: none;
    }

    .tooltip {
      width: 300px;
      background-color: #393b4f;
      color: #ffffff;
      border-radius: 4px;
      padding: 12px 6px;
      position: absolute;
      z-index: 600;
      bottom: 120%;
      font-size: 12px;
      left: 35%;
      margin-left: -60px;
      text-align: center;

      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #000000 transparent transparent transparent;
      }
    }

    .tooltipAllergen {
      float: left;
      width: 100%;
      padding: 6px;

      img {
        width: 16px;
        height: 16px;
        float: left;
      }

      span {
        margin-left: 6px;
        float: left;
        width: calc(100% - 36px);
      }
    }

    .tooltipNutrition {
      border-collapse: collapse;
      width: calc(100% - 24px);
      margin: 6px 12px;
      float: left;

      td {
        border: 1px solid #ffffff;
        padding: 6px;
      }
    }
  }

  .nutritionType {
    text-align: left;
  }

  .nutritionValue {
    text-align: right;
  }

  .nutritionInfo {
    background: #ffffff;
    color: #393b4f;

    &:first-child {
      border-left-color: #ffffff !important;
      border-right-color: #393b4f !important;
    }
  }
}