.ementas_digitais_LanguageItem {
  display: inline-block;
  width: 24px;
  margin: 0 9px;
  cursor: pointer;

  img {
    width: 100%;
  }
}

.chef_house_LanguageItem {
  display: inline-block;
  font-family: 'Helvetica-Light';
  width: 25px;
  margin: 0 12px 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
  color: #b6b8ba;
  height: 8px;

  div {
    width: 100%;
    text-align: center;
    font-size: 14px;
    letter-spacing: 2px;
    margin: -2px 0 0 2px;
  }
}

.chef_house_LanguageItemSelected  {
  color: #000000;
  box-shadow: 0 0 0 2px #000000;
}