.chef_house {
    .SakeItem {
      font-family: 'Helvetica';
      margin: 0 6px 24px 6px;
      box-shadow: 0 0 0 2px #b6b8ba;
      border-radius: 25px;
      padding: 10px 25px;
      border: none;
      cursor: pointer;
      color: #b6b8ba;
      font-weight: 600;
      background: transparent;
      outline: none;
      height: 32px;
    
      .title {
        width: 100%;
        line-height: 14px;
        text-align: center;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
  
      &.selected {
        color: #000000;
        box-shadow: 0 0 0 2px #000000;
      }
    }
  }