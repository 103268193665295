.chef_house {
    .OrderItem {
        position: relative;
        float: left;
        background: #ffffff;
        width: 100%;
        height: 124px;
        cursor: pointer;
        box-sizing: border-box;
        margin-bottom: 12px;
    }

    .OrderItemImageWrapper {
        float: left;
        width: 124px;
        height: 100%;
        overflow: hidden;
    }

    .OrderItemImage {
        width: 100%;
        height: 100%;
        transform: scale(1.25);
        background-size: cover;
        background-position: center;
      }

      .OrderItemName {
        float: left;
        width: 300px;
        height: 100%;
        font-family: 'Helvetica-BoldOblique';
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 12px;
      }

      .orderAmountControls {
        position: absolute;
        top: 0;
        left: 400px;
        height: 32px;
        padding: 48px 0;
        display: inline-block;
      }

      .changeOrderAmountBtn {
        float: left;
        font-family: 'Helvetica-Light';
        box-shadow: 0 0 0 2px #b6b8ba;
        border-radius: 25px;
        padding: 0 18px;
        border: none;
        cursor: pointer;
        color: #b6b8ba;
        text-align: center;
        background: transparent;
        outline: none;
        height: 32px;
        font-size: 18px;
        line-height: 0;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin: 0 6px 0 12px;
      }
  
      .orderAmount {
        float: left;
        font-family: 'Helvetica-Light';
        margin: 0 6px 0 12px;
        text-align: center;
        width: 32px;
        height: 100%;
        color: #000000;
        line-height: 36px;
      }

      .orderItemDosage {
        float: left;
        font-family: 'Helvetica-Light';
        box-shadow: 0 0 0 2px #b6b8ba;
        border-radius: 25px;
        border: none;
        cursor: pointer;
        color: #b6b8ba;
        text-align: center;
        background: transparent;
        outline: none;
        height: 32px;
        text-transform: uppercase;
        letter-spacing: 2px;
        width: 122px;
        margin: 0 6px 0 12px;
        font-size: 14px;
        line-height: 32px;

        &.hidden {
          visibility: hidden;
        }
      }

      .orderItemRemoveBtn {
        float: left;
        font-family: 'Helvetica-Light';
        box-shadow: 0 0 0 2px #b6b8ba;
        border-radius: 25px;
        border: none;
        cursor: pointer;
        color: #b6b8ba;
        text-align: center;
        background: transparent;
        outline: none;
        height: 32px;
        text-transform: uppercase;
        letter-spacing: 2px;
        width: 122px;
        margin: 0 6px 0 12px;
        font-size: 14px;
        padding: 10px 0;
      }
}
