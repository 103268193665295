.ementas_digitais {
  .WineItems {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 250px;
    height: 100%;
    z-index: 20;
    background-color: #393b4f;
    transition: transform 0.2s ease-out;
  }

  .Open {
    transform: translateX(0);
  }

  .Close {
    transform: translateX(-100%);
  }
}

.chef_house {
  .WineFilters {
    width: 75%;
    margin: 18px 12.5%;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .WineItems {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 354px;
    height: calc(100% - 160px);
    z-index: 20;
    background-color: #ffffff;
    transition: transform 0.2s ease-out;
  }

  .Open {
    transform: translateX(0);
  }

  .Close {
    transform: translateX(-100%);
  }
}