.chef_house {
    .LandingPage {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../../assets/images/backgrounds/chef_house_bg_1920x1080.jpg');
        background-size: cover;
        color: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        .logo {
            img {
                height: 250px;
            }
        }

        .buttons {
            margin: 48px 0;

            button {
                font-family: 'Helvetica-Light';
                margin: 0 12px 24px 12px;
                box-shadow: 0 0 0 2px #b6b8ba;
                border-radius: 25px;
                padding: 8px 10px;
                border: none;
                cursor: pointer;
                color: #b6b8ba;
                background: transparent;
                outline: none;
                height: 28px;
              
                .title {
                  width: 100%;
                  line-height: 14px;
                  text-align: center;
                  padding: 12px;
                  font-size: 12px;
                  text-transform: uppercase;
                }
            }
        }

        .info {
            margin-bottom: 12px;

            span {
                font-family: 'Helvetica-Oblique';
                font-size: 14px;
            }

            span + span::before {
                content: "\2022";
                margin: 0 12px;
            }
        }

        .enjoy {
            span {
                font-family: 'Helvetica';
                font-size: 18px;
                text-transform: uppercase;
            }

            span + span::before {
                content: "\2022";
                margin: 0 14px;
            }
        }
    }
}

@media only screen and (width: 1280px) and (height: 800px){
    .chef_house {
        .LandingPage {
            background: url('../../assets/images/backgrounds/chef_house_bg_1280x800.jpg');
        }
    }
  }

  @media only screen and (width: 1080px) and (height: 1920px){
    .chef_house {
        .LandingPage {
            background: url('../../assets/images/backgrounds/chef_house_bg_1080x1920.jpg');
        }
    }
  }