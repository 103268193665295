@font-face {
  font-family: 'Helvetica';
  src: url(/static/media/Helvetica.1b580d98.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-Light';
  src: url(/static/media/Helvetica-Light.2020b196.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-Oblique';
  src: url(/static/media/Helvetica-Oblique.5e9d01a5.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url(/static/media/Helvetica-Bold.d13db1fe.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-BoldOblique';
  src: url(/static/media/Helvetica-BoldOblique.1243b706.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-LightOblique';
  src: url(/static/media/Helvetica-LightOblique.09ed5351.ttf) format('truetype');
}

@font-face {
  font-family: 'FontAwesome-Solid';
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/fa-solid-900.cc6067cc.ttf) format('truetype');
}

@font-face {
  font-family: 'FontAwesome-Light';
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/fa-light-300.41dc641e.ttf) format('truetype');
}

@font-face {
  font-family: 'FontAwesome-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/fa-regular-400.6e691687.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  background: #eeeeee;
}

.WelcomeBanner_chef_house__2dc8k .WelcomeBanner_WelcomeBanner__2YAks {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 90vw;
  height: 90vh;
  left: 5%;
  top: 5%;
  box-sizing: border-box;
  transition: all 0.3s ease-out; }

.Backdrop_ementas_digitais__1ZbIQ .Backdrop_Backdrop__1CyHd {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.Backdrop_chef_house__nTE6- .Backdrop_Backdrop__1CyHd {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.LanguageItem_ementas_digitais_LanguageItem__3LZE6 {
  display: inline-block;
  width: 24px;
  margin: 0 9px;
  cursor: pointer; }
  .LanguageItem_ementas_digitais_LanguageItem__3LZE6 img {
    width: 100%; }

.LanguageItem_chef_house_LanguageItem__3qL3H {
  display: inline-block;
  font-family: 'Helvetica-Light';
  width: 25px;
  margin: 0 12px 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 10px;
  cursor: pointer;
  color: #b6b8ba;
  height: 8px; }
  .LanguageItem_chef_house_LanguageItem__3qL3H div {
    width: 100%;
    text-align: center;
    font-size: 14px;
    letter-spacing: 2px;
    margin: -2px 0 0 2px; }

.LanguageItem_chef_house_LanguageItemSelected__3QWc- {
  color: #000000;
  box-shadow: 0 0 0 2px #000000; }

.LanguageItems_ementas_digitais__2-2bX .LanguageItems_LanguageItems__eouI6 {
  float: left;
  width: 100%;
  text-align: center;
  margin: 6px auto; }

.LanguageItems_chef_house__t7Fj2 .LanguageItems_LanguageItems__eouI6 {
  float: left;
  font-family: 'Helvetica';
  width: 266px;
  text-align: center;
  margin: 18px auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_NavigationItem__2NITG {
  float: left;
  text-decoration: none;
  color: white;
  font-size: 14px;
  width: 275px;
  border: none !important;
  cursor: pointer;
  padding: 0px 0px;
  outline: none; }
  .NavigationItem_ementas_digitais__3qgF- .NavigationItem_NavigationItem__2NITG img {
    width: 35px;
    background-color: transparent;
    margin-left: 0px;
    padding: 6px 4px;
    float: left;
    vertical-align: middle;
    border: 0; }
  .NavigationItem_ementas_digitais__3qgF- .NavigationItem_NavigationItem__2NITG .NavigationItem_title__20vwy {
    float: left;
    margin: 16px;
    font-size: 14px; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_default__13p59 {
  background: #83d5ed;
  border-left: solid 12px #82d5ee;
  border-top: solid 12px #82d5ee; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_bread__nNqHJ {
  background: #e09b38;
  border-left: solid 12px #c18434;
  border-top: solid 12px #c18434; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_covered__1lyP0 {
  background: #5a8fd2;
  border-left: solid 12px #4d75a9;
  border-top: solid 12px #4d75a9; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_dessert__bs5CR {
  background: #714b80;
  border-left: solid 12px #543a60;
  border-top: solid 12px #543a60; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_drinks__3zzsZ {
  background: #4c9c9c;
  border-left: solid 12px #397575;
  border-top: solid 12px #397575; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_fish__1Tc3j {
  background: #bf6b90;
  border-left: solid 12px #9b5a79;
  border-top: solid 12px #9b5a79; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_meat__1rqLs {
  background: #72bbbc;
  border-left: solid 12px #5d9b9b;
  border-top: solid 12px #5d9b9b; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_salad__2NH41 {
  background: #2ecc71;
  border-left: solid 12px #27ae60;
  border-top: solid 12px #27ae60; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_soup__1Frrf {
  background: #c3cb33;
  border-left: solid 12px #a0a022;
  border-top: solid 12px #a0a022; }

.NavigationItem_ementas_digitais__3qgF- .NavigationItem_arrow__RQq1b {
  float: left;
  margin: 0 0 5px 250px;
  vertical-align: middle;
  width: 0;
  height: 0;
  background: none !important;
  border-bottom: solid 12px transparent;
  border-right: solid 12px transparent; }

@media screen and (max-height: 800px) {
  .NavigationItem_ementas_digitais__3qgF- .NavigationItem_NavigationItem__2NITG {
    width: 267px; }
    .NavigationItem_ementas_digitais__3qgF- .NavigationItem_NavigationItem__2NITG img {
      padding: 4px; }
    .NavigationItem_ementas_digitais__3qgF- .NavigationItem_NavigationItem__2NITG .NavigationItem_title__20vwy {
      margin: 14px 16px; }
  .NavigationItem_ementas_digitais__3qgF- .NavigationItem_arrow__RQq1b {
    border-width: 9px;
    margin-bottom: 7px; } }

.NavigationItem_chef_house__2rbHz .NavigationItem_NavigationItem__2NITG {
  font-family: 'Helvetica';
  background: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-decoration: none;
  color: #b6b8ba;
  font-size: 18px;
  text-transform: uppercase;
  width: 100%;
  border: none !important;
  cursor: pointer;
  padding: 0px;
  outline: none;
  letter-spacing: 2px; }
  .NavigationItem_chef_house__2rbHz .NavigationItem_NavigationItem__2NITG .NavigationItem_arrow__RQq1b {
    font-family: 'FontAwesome-Light'; }
  .NavigationItem_chef_house__2rbHz .NavigationItem_NavigationItem__2NITG .NavigationItem_title__20vwy {
    margin: 16px;
    font-size: 18px; }
    .NavigationItem_chef_house__2rbHz .NavigationItem_NavigationItem__2NITG .NavigationItem_title__20vwy.NavigationItem_selected__2ZEMw {
      color: #000000;
      text-decoration: none;
      border-bottom: 3px solid #000000; }

.DrinkItem_ementas_digitais__eAfny .DrinkItem_DrinkItem__12Y1s {
  text-decoration: none;
  color: white;
  background: #4c9c9c;
  font-size: 14px;
  width: 250px;
  border: none !important;
  cursor: pointer;
  margin-bottom: 25px;
  padding: 0px 0px;
  outline: none; }
  .DrinkItem_ementas_digitais__eAfny .DrinkItem_DrinkItem__12Y1s img {
    width: 35px;
    background-color: transparent;
    margin-left: 0px;
    padding: 7px 4px;
    float: left;
    vertical-align: middle;
    border: 0; }
  .DrinkItem_ementas_digitais__eAfny .DrinkItem_DrinkItem__12Y1s .DrinkItem_title__1FMMv {
    float: left;
    margin: 16px;
    font-size: 14px; }

.DrinkItem_chef_house__20IQl .DrinkItem_DrinkItem__12Y1s {
  font-family: 'Helvetica';
  background: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-decoration: none;
  color: #b6b8ba;
  font-size: 18px;
  text-transform: uppercase;
  width: 100%;
  border: none !important;
  cursor: pointer;
  padding: 0px;
  outline: none; }
  .DrinkItem_chef_house__20IQl .DrinkItem_DrinkItem__12Y1s .DrinkItem_arrow__3xDo2 {
    font-family: 'FontAwesome-Light'; }
  .DrinkItem_chef_house__20IQl .DrinkItem_DrinkItem__12Y1s .DrinkItem_title__1FMMv {
    margin: 16px;
    font-size: 18px;
    letter-spacing: 2px; }
    .DrinkItem_chef_house__20IQl .DrinkItem_DrinkItem__12Y1s .DrinkItem_title__1FMMv.DrinkItem_selected__2hPAz {
      color: #000000;
      text-decoration: none;
      border-bottom: 3px solid #000000; }

@media screen and (max-height: 800px) {
  .DrinkItem_DrinkItem__12Y1s img {
    padding: 4px; }
  .DrinkItem_DrinkItem__12Y1s .DrinkItem_title__1FMMv {
    font-size: 12px;
    margin: 14px 2px; } }

.WineMap_ementas_digitais__2QSGx .WineMap_WineMap__2_1uY {
  width: 250px;
  position: relative; }
  .WineMap_ementas_digitais__2QSGx .WineMap_WineMap__2_1uY img {
    width: 100%; }
  .WineMap_ementas_digitais__2QSGx .WineMap_WineMap__2_1uY button {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: none;
    cursor: pointer; }
    .WineMap_ementas_digitais__2QSGx .WineMap_WineMap__2_1uY button:hover {
      background: white; }
  .WineMap_ementas_digitais__2QSGx .WineMap_WineMap__2_1uY .WineMap_selected__2hDki {
    background: white; }

.WineMap_ementas_digitais__2QSGx .WineMap_AllWorld__1oZLZ {
  top: 20px;
  left: 23px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Acores__gWClK {
  top: 260px;
  left: 40px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Alentejo__1YfrK {
  top: 225px;
  left: 170px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Algarve__3P3f1 {
  top: 303px;
  left: 150px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Beiras__gjoU1 {
  top: 105px;
  left: 160px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Douro__3Mxov {
  top: 30px;
  left: 195px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Lisboa__l1bFq {
  top: 200px;
  left: 93px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Madeira__3t31Y {
  top: 160px;
  left: 30px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Minho__241JR {
  top: 35px;
  left: 135px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Setubal__2ArRL {
  top: 240px;
  left: 130px; }

.WineMap_ementas_digitais__2QSGx .WineMap_Tejo__3a0Q0 {
  top: 175px;
  left: 130px; }

.WineMap_ementas_digitais__2QSGx .WineMap_BackButton__2OPhd,
.WineMap_ementas_digitais__2QSGx .WineMap_AllRegionsButton__1YGls {
  text-decoration: none;
  color: white;
  background: #b9c7b6;
  font-size: 14px;
  border: none !important;
  cursor: pointer;
  margin: 20px 0;
  padding: 0px 0px;
  outline: none;
  min-height: 49px;
  text-align: left; }
  .WineMap_ementas_digitais__2QSGx .WineMap_BackButton__2OPhd .WineMap_title__2XH98,
  .WineMap_ementas_digitais__2QSGx .WineMap_AllRegionsButton__1YGls .WineMap_title__2XH98 {
    float: left;
    margin: 15px;
    font-size: 14px; }

.WineMap_ementas_digitais__2QSGx .WineMap_BackButton__2OPhd {
  width: 150px; }

.WineMap_ementas_digitais__2QSGx .WineMap_AllRegionsButton__1YGls {
  width: 250px; }

.WineMap_chef_house__3Coz4 .WineMap_WineMap__2_1uY {
  width: 354px;
  position: relative; }
  .WineMap_chef_house__3Coz4 .WineMap_WineMap__2_1uY .WineMap_map__1WUpY {
    width: 75%;
    margin: 0 12.5%; }
  .WineMap_chef_house__3Coz4 .WineMap_WineMap__2_1uY button {
    position: absolute;
    z-index: 20;
    width: 20px;
    height: 20px;
    opacity: 0;
    outline: none;
    border: none;
    cursor: pointer; }

.WineMap_chef_house__3Coz4 .WineMap_selectedRegion__36PwW {
  position: absolute;
  width: 75%;
  margin: 0 12.5%;
  z-index: 10;
  top: 108px;
  left: 0; }

.WineMap_chef_house__3Coz4 .WineMap_origin__1YYLb {
  float: left;
  width: 75%;
  margin: 6px 12.5% 0 12.5%;
  text-transform: uppercase;
  font-family: 'Helvetica';
  color: #b6b8ba;
  font-size: 18px;
  height: 48px;
  letter-spacing: 2px; }

.WineMap_chef_house__3Coz4 .WineMap_hint__-pteN {
  float: left;
  width: 75%;
  margin: 6px 12.5% 0 12.5%;
  font-family: 'Helvetica-BoldOblique';
  font-size: 14px;
  height: 48px; }

.WineMap_chef_house__3Coz4 .WineMap_AllWorld__1oZLZ {
  top: 330px;
  left: 37px;
  width: 110px !important;
  height: 100px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Acores__gWClK {
  top: 121px;
  left: 48px;
  width: 120px !important;
  height: 90px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Alentejo__1YfrK {
  top: 278px;
  left: 215px;
  height: 116px !important;
  width: 46px !important;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg); }

.WineMap_chef_house__3Coz4 .WineMap_Algarve__3P3f1 {
  top: 402px;
  left: 181px;
  width: 68px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Beiras__gjoU1 {
  top: 203px;
  left: 194px;
  width: 84px !important;
  height: 54px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Douro__3Mxov {
  top: 139px;
  left: 238px;
  width: 58px !important;
  height: 45px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Lisboa__l1bFq {
  top: 253px;
  left: 164px;
  height: 70px !important;
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg); }

.WineMap_chef_house__3Coz4 .WineMap_Madeira__3t31Y {
  top: 231px;
  left: 57px;
  width: 85px !important;
  height: 70px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Minho__241JR {
  top: 129px;
  left: 182px;
  width: 45px !important;
  height: 70px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Setubal__2ArRL {
  top: 323px;
  left: 171px;
  width: 35px !important;
  height: 53px !important; }

.WineMap_chef_house__3Coz4 .WineMap_Tejo__3a0Q0 {
  top: 264px;
  left: 196px;
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
  height: 53px !important; }

.WineMap_chef_house__3Coz4 .WineMap_arrow__2LTe0 {
  font-family: 'FontAwesome-Light';
  margin-right: 6px; }

.WineMap_chef_house__3Coz4 .WineMap_BackButton__2OPhd,
.WineMap_chef_house__3Coz4 .WineMap_AllRegionsButton__1YGls {
  margin: 0 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  background: transparent;
  outline: none;
  height: 28px;
  letter-spacing: 2px; }
  .WineMap_chef_house__3Coz4 .WineMap_BackButton__2OPhd .WineMap_title__2XH98,
  .WineMap_chef_house__3Coz4 .WineMap_AllRegionsButton__1YGls .WineMap_title__2XH98 {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase; }

.WineMap_chef_house__3Coz4 .WineMap_BackButton__2OPhd {
  width: 50%;
  margin: 12px 25% 24px 25%;
  font-family: 'Helvetica-Light';
  padding: 8px 10px; }
  .WineMap_chef_house__3Coz4 .WineMap_BackButton__2OPhd .WineMap_title__2XH98 {
    font-size: 12px; }

.WineMap_chef_house__3Coz4 .WineMap_AllRegionsButton__1YGls {
  width: 75%;
  margin: 12px 12.5%;
  padding: 10px 10px;
  font-weight: 600;
  font-family: 'Helvetica';
  height: 32px; }
  .WineMap_chef_house__3Coz4 .WineMap_AllRegionsButton__1YGls.WineMap_selected__2hDki {
    box-shadow: 0 0 0 2px #000000;
    color: #000000; }

.WineItem_ementas_digitais__2nkE2 .WineItem_WineItem__12X0x {
  text-decoration: none;
  color: white;
  background: #4c9c9c;
  font-size: 14px;
  width: 125px;
  float: left;
  border: none !important;
  cursor: pointer;
  margin-bottom: 25px;
  padding: 0px 0px;
  outline: none;
  min-height: 49px; }
  .WineItem_ementas_digitais__2nkE2 .WineItem_WineItem__12X0x .WineItem_title__2nlBm {
    float: left;
    margin: 15px;
    font-size: 16px; }

.WineItem_ementas_digitais__2nkE2 .WineItem_espumante__2sSZN {
  background: #b9c7b6; }

.WineItem_ementas_digitais__2nkE2 .WineItem_green_wine__3SuWw {
  background: #bac13a; }

.WineItem_ementas_digitais__2nkE2 .WineItem_red_wine__2fkmN {
  background: #900000; }

.WineItem_ementas_digitais__2nkE2 .WineItem_rose__1S30m {
  background: #f88a54; }

.WineItem_ementas_digitais__2nkE2 .WineItem_white_wine__3mlQt {
  background: #dfd2a2; }

.WineItem_chef_house__2ruc_ .WineItem_WineItem__12X0x {
  font-family: 'Helvetica';
  margin: 0 6px 24px 6px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  font-weight: 600;
  background: transparent;
  outline: none;
  height: 32px; }
  .WineItem_chef_house__2ruc_ .WineItem_WineItem__12X0x .WineItem_title__2nlBm {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .WineItem_chef_house__2ruc_ .WineItem_WineItem__12X0x.WineItem_selected__3suYd {
    color: #000000;
    box-shadow: 0 0 0 2px #000000; }

.WineItems_ementas_digitais__txPvG .WineItems_WineItems__1baxu {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 100%;
  z-index: 20;
  background-color: #393b4f;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }

.WineItems_ementas_digitais__txPvG .WineItems_Open__11nO_ {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.WineItems_ementas_digitais__txPvG .WineItems_Close__2uEiQ {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.WineItems_chef_house__2lFbI .WineItems_WineFilters__2BRiZ {
  width: 75%;
  margin: 18px 12.5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center; }

.WineItems_chef_house__2lFbI .WineItems_WineItems__1baxu {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 354px;
  height: calc(100% - 160px);
  z-index: 20;
  background-color: #ffffff;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }

.WineItems_chef_house__2lFbI .WineItems_Open__11nO_ {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.WineItems_chef_house__2lFbI .WineItems_Close__2uEiQ {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.SakeItem_chef_house__D4SCK .SakeItem_SakeItem__JPiOb {
  font-family: 'Helvetica';
  margin: 0 6px 24px 6px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  font-weight: 600;
  background: transparent;
  outline: none;
  height: 32px; }
  .SakeItem_chef_house__D4SCK .SakeItem_SakeItem__JPiOb .SakeItem_title__2mIK2 {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .SakeItem_chef_house__D4SCK .SakeItem_SakeItem__JPiOb.SakeItem_selected__3qIlM {
    color: #000000;
    box-shadow: 0 0 0 2px #000000; }

.SakeItems_chef_house__3otz8 .SakeItems_SakeFilters__3ZQ4p {
  width: 75%;
  margin: 18px 12.5%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center; }

.SakeItems_chef_house__3otz8 .SakeItems_SakeItems__bK3n0 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 354px;
  height: calc(100% - 160px);
  z-index: 20;
  background-color: #ffffff;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }

.SakeItems_chef_house__3otz8 .SakeItems_Open__1OXcR {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.SakeItems_chef_house__3otz8 .SakeItems_Close__G8G9b {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.SakeItems_chef_house_BackButton__3qLi0 {
  font-family: 'Helvetica-Light';
  width: 50%;
  margin: 0 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  background: transparent;
  margin: 12px 25%;
  outline: none;
  height: 28px;
  letter-spacing: 2px; }
  .SakeItems_chef_house_BackButton__3qLi0 .SakeItems_arrow__20UoO {
    font-family: 'FontAwesome-Light';
    margin-right: 6px; }
  .SakeItems_chef_house_BackButton__3qLi0 .SakeItems_title__1V-9y {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase; }

.DrinkItems_Open__1F1_h {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.DrinkItems_Close__28GX_ {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.DrinkItems_ementas_digitais_DrinkItems__2gUzk {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 250px;
  height: 90%;
  z-index: 10;
  background-color: #393b4f;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }

.DrinkItems_ementas_digitais_BackButton__2gb87 {
  text-decoration: none;
  color: white;
  background: #b9c7b6;
  font-size: 14px;
  width: 250px;
  border: none !important;
  cursor: pointer;
  margin-bottom: 25px;
  padding: 0px 0px;
  outline: none;
  min-height: 49px;
  text-align: left; }
  .DrinkItems_ementas_digitais_BackButton__2gb87 .DrinkItems_title__1yVvw {
    float: left;
    margin: 15px;
    font-size: 14px; }

.DrinkItems_chef_house_DrinkItems__243H4 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 354px;
  height: calc(100% - 160px);
  z-index: 10;
  background-color: #ffffff;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out; }

.DrinkItems_chef_house_BackButton__1NNqr {
  font-family: 'Helvetica-Light';
  width: 50%;
  margin: 0 12px;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  background: transparent;
  margin: 12px 25%;
  outline: none;
  height: 28px;
  letter-spacing: 2px; }
  .DrinkItems_chef_house_BackButton__1NNqr .DrinkItems_arrow__3nbtu {
    font-family: 'FontAwesome-Light';
    margin-right: 6px; }
  .DrinkItems_chef_house_BackButton__1NNqr .DrinkItems_title__1yVvw {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase; }

.SideDrawer_ementas_digitais__1yJwL .SideDrawer_SideDrawer__2au3d {
  float: left;
  position: fixed;
  background-color: #492e1d;
  width: 250px;
  height: 100%; }
  .SideDrawer_ementas_digitais__1yJwL .SideDrawer_SideDrawer__2au3d h6 {
    margin: 6px 0;
    color: #8185a3;
    text-align: center; }
  .SideDrawer_ementas_digitais__1yJwL .SideDrawer_SideDrawer__2au3d p {
    width: calc(250px - 12px);
    color: #ffffff;
    font-size: 9px;
    padding: 6px;
    text-align: left;
    position: fixed;
    bottom: 0; }

.SideDrawer_ementas_digitais__1yJwL .SideDrawer_Logo__1RwY6 {
  margin: 6px;
  text-align: center;
  background-color: #ffffff; }
  .SideDrawer_ementas_digitais__1yJwL .SideDrawer_Logo__1RwY6 img {
    height: 50px; }

.SideDrawer_ementas_digitais__1yJwL .SideDrawer_welcomeText__24tk2 {
  float: left;
  width: calc(100% - 325px);
  margin-left: 275px;
  padding: 25px;
  text-align: center; }
  .SideDrawer_ementas_digitais__1yJwL .SideDrawer_welcomeText__24tk2 h1 {
    font-size: 36px;
    font-weight: 500;
    margin: 0 0 10px 0; }
  .SideDrawer_ementas_digitais__1yJwL .SideDrawer_welcomeText__24tk2 h4 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px 0; }
  .SideDrawer_ementas_digitais__1yJwL .SideDrawer_welcomeText__24tk2 h5 {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 10px 0; }
  .SideDrawer_ementas_digitais__1yJwL .SideDrawer_welcomeText__24tk2 a {
    width: 100%;
    float: left;
    color: #337ab7;
    text-decoration: none;
    cursor: pointer; }
    .SideDrawer_ementas_digitais__1yJwL .SideDrawer_welcomeText__24tk2 a:hover {
      color: #23527c;
      text-decoration: underline; }

.SideDrawer_chef_house__1Y5De .SideDrawer_SideDrawer__2au3d {
  font-family: 'Helvetica';
  float: left;
  position: fixed;
  background-color: #eeeeee;
  height: 100%; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_SideDrawer__2au3d h6 {
    margin: 6px 0;
    color: #8185a3;
    text-align: center; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_SideDrawer__2au3d p {
    width: calc(250px - 12px);
    color: #000000;
    font-size: 9px;
    padding: 6px;
    text-align: left;
    position: fixed;
    bottom: 0; }

.SideDrawer_chef_house__1Y5De .SideDrawer_wrapper__27iGC {
  float: left;
  position: relative;
  background-color: #ffffff;
  width: 354px;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_wrapper__27iGC:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    top: 15%;
    right: 0;
    width: 10%;
    height: 70%;
    background: transparent; }

.SideDrawer_chef_house__1Y5De .SideDrawer_Logo__1RwY6 {
  display: inline-block;
  width: 100%;
  height: 140px;
  padding: 0px;
  margin: 6px;
  text-align: center; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_Logo__1RwY6 .SideDrawer_imageWrapper__3Oxdr {
    width: 100%;
    height: 120px;
    float: left; }
    .SideDrawer_chef_house__1Y5De .SideDrawer_Logo__1RwY6 .SideDrawer_imageWrapper__3Oxdr img {
      height: 100%;
      max-width: 100%; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_Logo__1RwY6 .SideDrawer_imageBottomDecorator__1kZQ4 {
    width: 100%;
    height: 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .SideDrawer_chef_house__1Y5De .SideDrawer_Logo__1RwY6 .SideDrawer_imageBottomDecorator__1kZQ4 .SideDrawer_decorator__2D7Bv {
      width: 95px;
      height: 100%;
      margin-top: 6px;
      background-color: #ffffff;
      background-image: url(/static/media/shadow.30518ed8.png);
      background-repeat: no-repeat;
      background-size: contain; }

.SideDrawer_chef_house__1Y5De .SideDrawer_Disclaimer__2cVGD {
  font-family: 'Helvetica';
  position: fixed;
  float: left;
  width: calc(354px - 96px);
  bottom: 0;
  left: 0;
  color: #eeeeee; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_Disclaimer__2cVGD .SideDrawer_disclaimerText__vNbcP {
    font-style: italic; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_Disclaimer__2cVGD.SideDrawer_selected__3scju {
    padding: 96px 48px;
    background: rgba(0, 0, 0, 0.8); }
    .SideDrawer_chef_house__1Y5De .SideDrawer_Disclaimer__2cVGD.SideDrawer_selected__3scju .SideDrawer_viewOrder__1o6ix {
      visibility: hidden; }

.SideDrawer_chef_house__1Y5De .SideDrawer_Info__3wETX {
  position: fixed;
  left: 12px;
  bottom: 12px;
  width: 24px;
  height: 24px;
  border: 1px solid #b6b8ba;
  border-radius: 50%;
  padding: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  background: url(/static/media/info-sign.2b975b59.svg);
  background-size: 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.SideDrawer_chef_house__1Y5De .SideDrawer_viewOrder__1o6ix {
  position: fixed;
  left: calc(354px - 142px);
  bottom: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 56px;
  visibility: visible;
  cursor: pointer; }

.SideDrawer_chef_house__1Y5De .SideDrawer_viewOrderText__1Y5oO {
  font-family: "Helvetica-Light";
  color: #b6b8ba;
  float: left; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_viewOrderText__1Y5oO.SideDrawer_selected__3scju {
    color: #000000; }

.SideDrawer_chef_house__1Y5De .SideDrawer_viewOrderIcon__1x9Oa {
  width: 48px;
  height: 48px;
  padding: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background: url(/static/media/view-order-icon.3b87d4be.svg);
  background-size: 48px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  float: left; }
  .SideDrawer_chef_house__1Y5De .SideDrawer_viewOrderIcon__1x9Oa.SideDrawer_selected__3scju {
    background: url(/static/media/view-order-icon-selected.6d48e080.svg); }

.SideDrawer_chef_house__1Y5De .SideDrawer_welcomeText__24tk2 {
  display: none; }

.SideDrawer_chef_house__1Y5De .SideDrawer_welcomeBanner__2rKsQ {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center; }

.Modal_ementas_digitais__1D3rM .Modal_Modal__gMPRg {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: calc(570px + 32px);
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 30%;
  top: 5%;
  box-sizing: border-box;
  border-radius: 10px;
  transition: all 0.3s ease-out; }

.Modal_chef_house__3HW61 .Modal_Modal__gMPRg {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 55%;
  left: 30%;
  top: 5%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  overflow: scroll; }

@media only screen and (width: 1280px) and (height: 800px) {
  .Modal_chef_house__3HW61 .Modal_Modal__gMPRg {
    width: 570px;
    height: 690px;
    left: 525px;
    top: 50px; } }

@media only screen and (width: 1920px) and (height: 1080px) {
  .Modal_chef_house__3HW61 .Modal_Modal__gMPRg {
    width: 975px;
    height: 945px;
    left: 598px;
    top: 68px; } }

@media only screen and (width: 1080px) and (height: 1920px) {
  .Modal_chef_house__3HW61 .Modal_Modal__gMPRg {
    width: 548px;
    height: 840px;
    left: 435px;
    top: 270px; } }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodImage__qitIR {
  float: left;
  width: 570px;
  height: 330px;
  margin: 6px 0; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodImage__qitIR img {
    margin-left: auto;
    margin-right: auto;
    display: block; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodMain__2Q0uP {
  width: 100%;
  float: left; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodInfo__273rg {
  width: 100%;
  float: left; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodName__2AQym {
  width: 65%;
  float: left;
  font-size: 18px; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodPrice__2DK20 {
  width: 35%;
  float: left;
  text-align: right;
  font-size: 14px;
  color: #5a8fd2; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodPrice__2DK20 span {
    width: 100%;
    float: left; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_vatInfo__1jvy9 {
  float: left;
  width: 100%;
  text-align: right;
  font-size: 10px;
  color: #5a8fd2; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodNutrition__1OOHB {
  background: #5a8fd2;
  width: 100%;
  box-sizing: border-box;
  color: #ffffff;
  padding: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  position: relative; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodNutrition__1OOHB img {
    margin-left: 6px;
    width: 16px;
    height: 16px; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_foodDescription__1-9cc {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  font-size: 18px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  position: relative;
  max-height: 150px;
  overflow: scroll; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN button {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: #333333;
  background-color: #ffffff;
  border: #cccccc solid 1px;
  outline: none; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_tooltip__3gSn3 {
  width: 300px;
  background-color: #393b4f;
  color: #ffffff;
  border-radius: 4px;
  padding: 12px 6px;
  position: absolute;
  z-index: 600;
  bottom: 120%;
  font-size: 12px;
  left: 35%;
  margin-left: -60px;
  text-align: center; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_tooltip__3gSn3:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_tooltipAllergen__190dO {
  float: left;
  width: 100%;
  padding: 6px; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_tooltipAllergen__190dO img {
    width: 16px;
    height: 16px;
    float: left; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_tooltipAllergen__190dO span {
    margin-left: 6px;
    float: left;
    width: calc(100% - 36px); }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_tooltipNutrition__XrqpM {
  border-collapse: collapse;
  width: calc(100% - 24px);
  margin: 6px 12px;
  float: left; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_FoodItemDetails__B3chN .FoodItemDetails_tooltipNutrition__XrqpM td {
    border: 1px solid #ffffff;
    padding: 6px; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_nutritionType__1N8IN {
  text-align: left; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_nutritionValue__NAD4J {
  text-align: right; }

.FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_nutritionInfo__2zbAQ {
  background: #ffffff;
  color: #393b4f; }
  .FoodItemDetails_ementas_digitais__2Hvwm .FoodItemDetails_nutritionInfo__2zbAQ:first-child {
    border-left-color: #ffffff !important;
    border-right-color: #393b4f !important; }

.FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad {
  font-family: 'Helvetica';
  position: relative; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodImage__2Ue0Y {
    float: left;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    height: 410px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodMain__3_tNl {
    width: 100%;
    float: left;
    position: relative;
    background: #ffffff;
    margin-top: 24px; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodMain__3_tNl:after {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
      bottom: 0px;
      left: 35%;
      right: 10%;
      width: 30%;
      height: 50%;
      border-radius: 100%; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodInfo__17Rht {
    width: 100%;
    float: left; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodName__3sNQf {
    font-family: 'Helvetica-BoldOblique';
    width: 100%;
    float: left;
    font-size: 20px;
    text-align: center;
    margin: 12px 0 6px 0; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodPrice__2QPo7 {
    width: calc(100% - 24px);
    float: left;
    font-size: 14px;
    color: #000000;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 12px;
    padding: 0 12px; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodPrice__2QPo7 span {
      float: left;
      margin-right: 6px; }
      .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodPrice__2QPo7 span:last-child {
        margin-right: 0; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodNutrition__3Q_mZ {
    font-family: 'Helvetica-Light';
    margin: 0 12.5% 24px 12.5%;
    box-shadow: 0 0 0 2px #b6b8ba;
    border-radius: 25px;
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    color: #b6b8ba;
    text-align: center;
    background: transparent;
    outline: none;
    height: 14px;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_foodDescription__1ggqI {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    padding: 12px 16px;
    font-size: 14px;
    position: relative;
    margin: 0; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_orderControls__1Xwkj {
    position: relative;
    height: 32px;
    margin: 0 12.5% 24px 12.5%; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_orderAmountControls__2dyWf {
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    margin-right: 12px;
    display: inline-block; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderAmountBtn__FAOvl {
    float: left;
    font-family: 'Helvetica-Light';
    box-shadow: 0 0 0 2px #b6b8ba;
    border-radius: 25px;
    padding: 0 18px;
    border: none;
    cursor: pointer;
    color: #b6b8ba;
    text-align: center;
    background: transparent;
    outline: none;
    height: 32px;
    width: 48px;
    font-size: 18px;
    line-height: 0;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_orderAmount__21YQm {
    float: left;
    font-family: 'Helvetica-Light';
    margin: 0 12px;
    text-align: center;
    width: 32px;
    color: #000000;
    line-height: 36px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderDosageCloseWrapper__1e20N {
    position: absolute;
    top: 0;
    left: 40%;
    font-family: 'Helvetica';
    box-shadow: 0 0 0 2px #b6b8ba;
    background: transparent;
    border-radius: 25px;
    padding: 0px 12px;
    border: none;
    cursor: pointer;
    outline: none;
    height: 32px;
    width: 134px;
    font-size: 12px;
    line-height: 34px;
    color: #000000; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderDosageClose__3DMut {
    width: 100%; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderDosageCloseText__1LzxL {
    float: left;
    width: 90%; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderDosageOpenWrapper__3Mx38 {
    position: absolute;
    bottom: 0;
    left: 40%;
    font-family: 'Helvetica';
    box-shadow: 0 0 0 2px #b6b8ba;
    background: #ffffff;
    border-radius: 25px;
    padding: 0px 12px;
    border: none;
    cursor: pointer;
    outline: none;
    width: 134px;
    font-size: 12px;
    line-height: 34px;
    color: #000000; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderDosageOpenWrapper__3Mx38 div {
      color: #b6b8ba;
      padding: 12px 0; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderDosageOpen__1tGQQ {
    width: 100%; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_changeOrderDosageOpenText__iGB9k {
    float: left;
    width: 90%;
    border-top: 1px solid #b6b8ba;
    padding-top: 6px;
    color: #000000; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_chevron__1GAYs {
    float: left;
    color: #000000;
    width: 10%; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_chevron__1GAYs:before {
      border-style: solid;
      border-width: 0.25em 0.25em 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: 0.15em;
      position: relative;
      top: 0.15em;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      vertical-align: top;
      width: 0.45em;
      top: 20px; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_chevron__1GAYs.FoodItemDetailsChefHouse_bottom__3U_Wz:before {
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
      top: 10px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_orderBtn__1LgCa {
    position: absolute;
    top: 0;
    right: 0;
    font-family: 'Helvetica-Light';
    box-shadow: 0 0 0 2px #000000;
    background: #000000;
    border-radius: 25px;
    padding: 0 18px;
    border: none;
    cursor: pointer;
    color: #b6b8ba;
    text-align: center;
    outline: none;
    height: 32px;
    font-size: 12px;
    line-height: 32px;
    text-transform: uppercase;
    letter-spacing: 2px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_tooltip__2onUl {
    width: calc(80% - 24px);
    height: calc(100% - 68px);
    background-color: #ffffff;
    color: #000000;
    margin: 24px 10%;
    padding: 12px;
    position: fixed;
    z-index: 600;
    font-size: 12px;
    text-align: center;
    top: 0;
    left: 0;
    border: 1px solid transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-justify-content: center;
            justify-content: center; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_tooltipTitle__3HNeI {
    width: 100%;
    float: left; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_tooltipTitle__3HNeI h2 {
      text-align: center;
      width: 100%;
      float: left;
      font-size: 18px;
      font-family: 'Helvetica-BoldOblique';
      margin: 6px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_allergies__3vhfl {
    float: left;
    width: 100%;
    position: relative;
    background: #ffffff;
    padding-bottom: 24px;
    margin-bottom: 24px; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_allergies__3vhfl:after {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
      bottom: 0px;
      left: 35%;
      right: 10%;
      width: 30%;
      height: 50%;
      border-radius: 100%; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_tooltipAllergen__3JvN9 {
    float: left;
    width: 100%;
    padding: 6px; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_tooltipAllergen__3JvN9 span {
      text-align: center;
      font-size: 14px;
      float: left;
      width: 100%; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_nutrition__1O8PT {
    float: left;
    width: 100%;
    margin-bottom: 12px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_tooltipNutrition__3rpW9 {
    font-family: 'Helvetica';
    border-collapse: separate;
    border-spacing: 10px;
    width: 50%;
    margin: 6px 25%;
    float: left; }
    .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_FoodItemDetails__D93Ad .FoodItemDetailsChefHouse_tooltipNutrition__3rpW9 td {
      border-bottom: 1px solid #b6b8ba;
      padding: 6px; }

.FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_nutritionType__3pgcT {
  text-align: right; }

.FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_nutritionValue__2__7N {
  text-align: center; }

.FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_nutritionInfo__3ObJP {
  font-family: 'Helvetica-BoldOblique'; }

.FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_arrow__1nh_b {
  font-family: 'FontAwesome-Light';
  margin-right: 6px; }

.FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_backButton__2_cX_ {
  font-family: 'Helvetica-Light';
  margin: 0 35% 24px 35%;
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  text-align: center;
  background: transparent;
  outline: none;
  height: 28px;
  margin-top: 6px;
  letter-spacing: 2px; }
  .FoodItemDetailsChefHouse_chef_house__3UgYX .FoodItemDetailsChefHouse_backButton__2_cX_ .FoodItemDetailsChefHouse_title__3XeQM {
    width: 100%;
    line-height: 14px;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase; }

@-webkit-keyframes FoodItem_appear__rq34e {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes FoodItem_appear__rq34e {
  from {
    -webkit-transform: scale(0);
            transform: scale(0); }
  to {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_hide__3f5FI {
  display: none; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_FoodItem__17pnb {
  float: left;
  width: 225px;
  height: 300px;
  margin-left: 12px;
  cursor: pointer;
  -webkit-animation: FoodItem_appear__rq34e 0.25s;
          animation: FoodItem_appear__rq34e 0.25s; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_FoodItemImageWrapper__3UH1e {
  float: left;
  width: 100%;
  height: 200px;
  overflow: hidden; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_FoodItemImage__2yP1I {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  background-size: cover;
  background-position: center; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_FoodItemName__3oFPP {
  float: left;
  display: table;
  color: white;
  width: 100%;
  height: 65px;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  font-size: 12px;
  transition: background-color 120s step-end; }
  .FoodItem_ementas_digitais__2jdzJ .FoodItem_FoodItemName__3oFPP p {
    display: table-cell;
    vertical-align: middle;
    padding: 6px; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_default__31yxz {
  background: #83d5ed; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_bread__MZ9uw {
  background: #e09b38; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_covered__6V0Nu {
  background: #5a8fd2; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_dessert__3ibtM {
  background: #714b80; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_drinks__3DGH8 {
  background: #4c9c9c; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_fish__1UAsh {
  background: #bf6b90; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_meat__1iDpR {
  background: #72bbbc; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_salad__3bPTk {
  background: #2ecc71; }

.FoodItem_ementas_digitais__2jdzJ .FoodItem_soup__3O5Zh {
  background: #c3cb33; }

.FoodItem_chef_house__29pOH .FoodItem_hide__3f5FI {
  display: none; }

.FoodItem_chef_house__29pOH .FoodItem_FoodItem__17pnb {
  font-family: 'Helvetica';
  float: left;
  width: 275px;
  height: 365px;
  margin-left: 12px;
  cursor: pointer;
  -webkit-animation: FoodItem_appear__rq34e 0.25s;
          animation: FoodItem_appear__rq34e 0.25s; }

.FoodItem_chef_house__29pOH .FoodItem_FoodItemImageWrapper__3UH1e {
  float: left;
  width: 100%;
  height: 200px;
  overflow: hidden; }

.FoodItem_chef_house__29pOH .FoodItem_FoodItemImage__2yP1I {
  width: 100%;
  height: 200px;
  background-position: center;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transform: scale(1.25);
          transform: scale(1.25); }

.FoodItem_chef_house__29pOH .FoodItem_FoodItemName__3oFPP {
  float: left;
  color: #000000;
  width: calc(100% - 12px);
  display: table;
  height: 135px;
  text-align: center;
  margin: 0;
  font-size: 12px;
  padding: 6px; }
  .FoodItem_chef_house__29pOH .FoodItem_FoodItemName__3oFPP span {
    display: table-cell;
    margin-top: 6px; }
  .FoodItem_chef_house__29pOH .FoodItem_FoodItemName__3oFPP .FoodItem_itemName__3n1dq {
    font-family: 'Helvetica-BoldOblique';
    font-size: 18px;
    font-weight: 900;
    float: left;
    width: 100%; }
  .FoodItem_chef_house__29pOH .FoodItem_FoodItemName__3oFPP .FoodItem_itemPrice__fhRWo {
    float: left;
    font-size: 12px;
    width: 100%; }
  .FoodItem_chef_house__29pOH .FoodItem_FoodItemName__3oFPP .FoodItem_itemMore__BmuDi {
    float: left;
    color: #b6b8ba;
    width: 100%; }

.FoodItem_chef_house__29pOH .FoodItem_default__31yxz {
  background: #ffffff;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-justify-content: center;
          justify-content: center; }
  .FoodItem_chef_house__29pOH .FoodItem_default__31yxz:after {
    content: "";
    position: absolute;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    bottom: 0px;
    left: 10%;
    right: 10%;
    width: 80%;
    height: 50%;
    border-radius: 100%; }

.FoodItems_ementas_digitais__3-npC .FoodItems_FoodItems__2ELpH {
  float: left;
  width: calc(100% - 325px);
  margin-left: 275px;
  padding: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: stretch;
          align-content: stretch; }

.FoodItems_chef_house__35LNJ .FoodItems_FoodItems__2ELpH {
  float: left;
  font-family: 'Helvetica';
  width: calc(100% - 375px);
  margin-left: 325px;
  padding: 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: stretch;
          align-content: stretch; }

.FoodItems_chef_house__35LNJ .FoodItems_WineFilters__mrfXt {
  width: 100%;
  margin-top: 12px;
  margin-left: 25px;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.FoodItems_chef_house__35LNJ .FoodItems_SakeFilters__6GNou {
  width: 100%;
  margin-top: 12px;
  margin-left: 25px;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(/static/media/chef_house_bg_1920x1080.28033cc2.jpg);
  background-size: cover;
  color: #eeeeee;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-flow: column;
          flex-flow: column; }
  .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_logo__3nDvu img {
    height: 250px; }
  .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_buttons__1bvtA {
    margin: 48px 0; }
    .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_buttons__1bvtA button {
      font-family: 'Helvetica-Light';
      margin: 0 12px 24px 12px;
      box-shadow: 0 0 0 2px #b6b8ba;
      border-radius: 25px;
      padding: 8px 10px;
      border: none;
      cursor: pointer;
      color: #b6b8ba;
      background: transparent;
      outline: none;
      height: 28px; }
      .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_buttons__1bvtA button .LandingPage_title__3FFij {
        width: 100%;
        line-height: 14px;
        text-align: center;
        padding: 12px;
        font-size: 12px;
        text-transform: uppercase; }
  .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_info__1yLXT {
    margin-bottom: 12px; }
    .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_info__1yLXT span {
      font-family: 'Helvetica-Oblique';
      font-size: 14px; }
    .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_info__1yLXT span + span::before {
      content: "\2022";
      margin: 0 12px; }
  .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_enjoy__3EsTB span {
    font-family: 'Helvetica';
    font-size: 18px;
    text-transform: uppercase; }
  .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C .LandingPage_enjoy__3EsTB span + span::before {
    content: "\2022";
    margin: 0 14px; }

@media only screen and (width: 1280px) and (height: 800px) {
  .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C {
    background: url(/static/media/chef_house_bg_1280x800.27af554d.jpg); } }

@media only screen and (width: 1080px) and (height: 1920px) {
  .LandingPage_chef_house__2Sl32 .LandingPage_LandingPage__3_K-C {
    background: url(/static/media/chef_house_bg_1080x1920.19b15381.jpg); } }

.OrderItem_chef_house__209MA .OrderItem_OrderItem__2XRl2 {
  position: relative;
  float: left;
  background: #ffffff;
  width: 100%;
  height: 124px;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 12px; }

.OrderItem_chef_house__209MA .OrderItem_OrderItemImageWrapper__3dHZo {
  float: left;
  width: 124px;
  height: 100%;
  overflow: hidden; }

.OrderItem_chef_house__209MA .OrderItem_OrderItemImage__2j7nP {
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  background-size: cover;
  background-position: center; }

.OrderItem_chef_house__209MA .OrderItem_OrderItemName__2Sc97 {
  float: left;
  width: 300px;
  height: 100%;
  font-family: 'Helvetica-BoldOblique';
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0 12px; }

.OrderItem_chef_house__209MA .OrderItem_orderAmountControls__2tBsa {
  position: absolute;
  top: 0;
  left: 400px;
  height: 32px;
  padding: 48px 0;
  display: inline-block; }

.OrderItem_chef_house__209MA .OrderItem_changeOrderAmountBtn__2lyH7 {
  float: left;
  font-family: 'Helvetica-Light';
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  padding: 0 18px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  text-align: center;
  background: transparent;
  outline: none;
  height: 32px;
  font-size: 18px;
  line-height: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0 6px 0 12px; }

.OrderItem_chef_house__209MA .OrderItem_orderAmount__3OBf8 {
  float: left;
  font-family: 'Helvetica-Light';
  margin: 0 6px 0 12px;
  text-align: center;
  width: 32px;
  height: 100%;
  color: #000000;
  line-height: 36px; }

.OrderItem_chef_house__209MA .OrderItem_orderItemDosage__u1_l8 {
  float: left;
  font-family: 'Helvetica-Light';
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  text-align: center;
  background: transparent;
  outline: none;
  height: 32px;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 122px;
  margin: 0 6px 0 12px;
  font-size: 14px;
  line-height: 32px; }
  .OrderItem_chef_house__209MA .OrderItem_orderItemDosage__u1_l8.OrderItem_hidden__3rA1Y {
    visibility: hidden; }

.OrderItem_chef_house__209MA .OrderItem_orderItemRemoveBtn__1aT-H {
  float: left;
  font-family: 'Helvetica-Light';
  box-shadow: 0 0 0 2px #b6b8ba;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  color: #b6b8ba;
  text-align: center;
  background: transparent;
  outline: none;
  height: 32px;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 122px;
  margin: 0 6px 0 12px;
  font-size: 14px;
  padding: 10px 0; }

.OrderItems_chef_house__38bGC .OrderItems_OrderItems__16QrD {
  float: left;
  font-family: 'Helvetica';
  width: calc(100% - 400px);
  margin-left: 354px;
  padding: 25px;
  height: calc(100vh - 124px);
  margin-bottom: 12px;
  overflow: scroll; }

.OrderItems_chef_house__38bGC .OrderItems_OrderItemsRemoveAll__YCWPO {
  float: left;
  font-family: 'Helvetica';
  width: calc(100% - 400px);
  margin-left: 354px;
  padding: 0 25px;
  margin-top: 12px;
  height: 48px; }

.OrderItems_chef_house__38bGC .OrderItems_OrderItemsRemoveAllIcon__3HC1c {
  width: 32px;
  height: 48px;
  padding: 4px;
  margin-top: -14px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background: url(/static/media/delete-order-icon.6ac33245.svg);
  background-size: 48px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  float: right; }

