@font-face {
  font-family: 'Helvetica';
  src: url('./assets/fonts/Helvetica.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica-Light';
  src: url('./assets/fonts/Helvetica-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica-Oblique';
  src: url('./assets/fonts/Helvetica-Oblique.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('./assets/fonts/Helvetica-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica-BoldOblique';
  src: url('./assets/fonts/Helvetica-BoldOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica-LightOblique';
  src: url('./assets/fonts/Helvetica-LightOblique.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome-Solid';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/fontawesome/fa-solid-900.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome-Light';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/fontawesome/fa-light-300.ttf') format('truetype');
}

@font-face {
  font-family: 'FontAwesome-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/fontawesome/fa-regular-400.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html {
  background: #eeeeee;
}
