.ementas_digitais {
  .LanguageItems {
    float: left;
    width: 100%;
    text-align: center;
    margin: 6px auto;
  }
}

.chef_house {
  .LanguageItems {
    float: left;
    font-family: 'Helvetica';
    width: 266px;
    text-align: center;
    margin: 18px auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
