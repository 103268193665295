.ementas_digitais {
  .Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: calc(570px + 32px);
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 30%;
    top: 5%;
    box-sizing: border-box;
    border-radius: 10px;
    transition: all 0.3s ease-out;
  }
}

.chef_house {
  .Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 55%;
    left: 30%;
    top: 5%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    overflow: scroll;
  }

  @media only screen and (width: 1280px) and (height: 800px) {
    .Modal {
      width: 570px;
      height: 690px;
      left: 525px;
      top: 50px;
    }
  }

  @media only screen and (width: 1920px) and (height: 1080px) {
    .Modal {
      width: 975px;
      height: 945px;
      left: 598px;
      top: 68px;
    }
  }

  @media only screen and (width: 1080px) and (height: 1920px) {
    .Modal {
      width: 548px;
      height: 840px;
      left: 435px;
      top: 270px;
    }
  }
}
