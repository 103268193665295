.ementas_digitais {
  .WineMap {
    width: 250px;
    position: relative;

    img {
      width: 100%;
    }

    button {
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      outline: none;
      border: none;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 1);
      }
    }

    .selected {
      background: rgba(255, 255, 255, 1);
    }
  }

  .AllWorld {
    top: 20px;
    left: 23px;
  }

  .Acores {
    top: 260px;
    left: 40px;
  }

  .Alentejo {
    top: 225px;
    left: 170px;
  }

  .Algarve {
    top: 303px;
    left: 150px;
  }

  .Beiras {
    top: 105px;
    left: 160px;
  }

  .Douro {
    top: 30px;
    left: 195px;
  }

  .Lisboa {
    top: 200px;
    left: 93px;
  }

  .Madeira {
    top: 160px;
    left: 30px;
  }

  .Minho {
    top: 35px;
    left: 135px;
  }

  .Setubal {
    top: 240px;
    left: 130px;
  }

  .Tejo {
    top: 175px;
    left: 130px;
  }

  .BackButton,
  .AllRegionsButton {
    text-decoration: none;
    color: white;
    background: #b9c7b6;
    font-size: 14px;
    border: none !important;
    cursor: pointer;
    margin: 20px 0;
    padding: 0px 0px;
    outline: none;
    min-height: 49px;
    text-align: left;

    .title {
      float: left;
      margin: 15px;
      font-size: 14px;
    }
  }

  .BackButton {
    width: 150px;
  }

  .AllRegionsButton {
    width: 250px;
  }
}

.chef_house {
  .WineMap {
    width: 354px;
    position: relative;

    .map {
      width: 75%;
      margin: 0 12.5%;
    }

    button {
      position: absolute;
      z-index: 20;
      width: 20px;
      height: 20px;
      opacity: 0;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }

  .selectedRegion {
    position: absolute;
    width: 75%;
    margin: 0 12.5%;
    z-index: 10;
    top: 108px;
    left: 0;
  }

  .origin {
    float: left;
    width: 75%;
    margin: 6px 12.5% 0 12.5%;
    text-transform: uppercase;
    font-family: 'Helvetica';
    color: #b6b8ba;
    font-size: 18px;
    height: 48px;
    letter-spacing: 2px;
  }

  .hint {
    float: left;
    width: 75%;
    margin: 6px 12.5% 0 12.5%;
    font-family: 'Helvetica-BoldOblique';
    font-size: 14px;
    height: 48px;
  }

  .AllWorld {
    top: 330px;
    left: 37px;
    width: 110px !important;
    height: 100px !important;
  }

  .Acores {
    top: 121px;
    left: 48px;
    width: 120px !important;
    height: 90px !important;
  }

  .Alentejo {
    top: 278px;
    left: 215px;
    height: 116px !important;
    width: 46px !important;
    transform: rotate(15deg);
  }

  .Algarve {
    top: 402px;
    left: 181px;
    width: 68px !important;
  }

  .Beiras {
    top: 203px;
    left: 194px;
    width: 84px !important;
    height: 54px !important;
  }

  .Douro {
    top: 139px;
    left: 238px;
    width: 58px !important;
    height: 45px !important;
  }

  .Lisboa {
    top: 253px;
    left: 164px;
    height: 70px !important;
    transform: rotate(25deg);
  }

  .Madeira {
    top: 231px;
    left: 57px;
    width: 85px !important;
    height: 70px !important;
  }

  .Minho {
    top: 129px;
    left: 182px;
    width: 45px !important;
    height: 70px !important;
  }

  .Setubal {
    top: 323px;
    left: 171px;
    width: 35px !important;
    height: 53px !important;
  }

  .Tejo {
    top: 264px;
    left: 196px;
    transform: rotate(25deg);
    height: 53px !important;
  }

  .arrow {
    font-family: 'FontAwesome-Light';
    margin-right: 6px;
  }

  .BackButton,
  .AllRegionsButton {
    margin: 0 12px;
    box-shadow: 0 0 0 2px #b6b8ba;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    color: #b6b8ba;
    background: transparent;
    outline: none;
    height: 28px;
    letter-spacing: 2px;
  
    .title {
      width: 100%;
      line-height: 14px;
      text-align: center;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .BackButton {
    width: 50%;
    margin: 12px 25% 24px 25%;
    font-family: 'Helvetica-Light';
    padding: 8px 10px;

    .title {
      font-size: 12px;
    }
  }

  .AllRegionsButton {
    width: 75%;
    margin: 12px 12.5%;
    padding: 10px 10px;
    font-weight: 600;
    font-family: 'Helvetica';
    height: 32px;

    &.selected {
      box-shadow: 0 0 0 2px #000000;
      color: #000000;
    }
  }
}
